/* Base styles */
.logcontainer {
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

button {
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #555;
}

p {
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 1.2em;
  margin-top: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .logcontainer {
    padding: 15px;
  }

  h2 {
    font-size: 1.2em;
  }

  input, button {
    font-size: 0.9em;
  }

  p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .logcontainer {
    padding: 10px;
  }

  h2 {
    font-size: 1em;
  }

  input, button {
    font-size: 0.8em;
  }

  p {
    font-size: 0.9em;
  }
}

@media (max-width: 320px) {
  .logcontainer {
    padding: 5px;
  }

  h2 {
    font-size: 0.9em;
  }

  input, button {
    font-size: 0.7em;
  }

  p {
    font-size: 0.8em;
  }
}
