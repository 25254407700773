/* .Content-container {
  margin: 20px;
}

button {
  margin: 5px;
}

form {
  display: inline-block;
}
*/
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

a {
  margin-right: 10px;
} 


.container {
  padding-top: 60px;
  margin: 20px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  box-sizing: border-box;
  margin: 10px;
  height: 100vh; /* Full height for scrolling */
  display: flex;
  width: 100%;
}

.tabsbuttonstage {
  width: 10%;
  background-color: rgb(0, 0, 0); /* Optional: For better visibility */
  padding: 10px;
  box-sizing: border-box; /* Ensures padding is included in width */
  position: fixed;
}

.Content-container {
  width: 90%;
  padding: 10px;
  box-sizing: border-box; /* Ensures padding is included in width */
  padding-left: 15%;
}

@media (max-width: 1200px) {
  .Content-container {
    margin: 10px;
    padding-left: 10%; /* Adjust padding for better fit */
  }

  .tabsbuttonstage {
    width: 15%; /* Increase width for better visibility */
  }
}
@media (max-width: 992px) {
  .Content-container {
    padding-left: 5%; /* Reduce padding for smaller screens */
  }

  .tabsbuttonstage {
    width: 20%; /* Increase width to accommodate more space */
  }
}
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack elements vertically */
    padding-top: 40px; /* Reduce top padding */
  }

  .tabsbuttonstage {
    width: 25%; /* Further increase width */
    padding: 5px; /* Reduce padding */
  }

  .Content-container {
    width: 100%; /* Full width */
    padding-left: 0; /* Remove left padding */
    padding: 5px; /* Reduce overall padding */
  }
}
@media (max-width: 576px) {
  .container {
    padding: 10px; /* Reduce padding */
    margin: 0; /* Remove margin */
  }

  .tabsbuttonstage {
    width: 100%; /* Full width for better use of space */
    padding: 10px; /* Adjust padding */
    position: static; /* Remove fixed positioning */
  }

  .Content-container {
    width: 100%; /* Full width */
    padding-left: 0; /* Remove left padding */
  }
}
