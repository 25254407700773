.home-container {
    height: 100vh;
    width: 100%;
    background-image: url('../assets/Home/home.jpg'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .content {
    background-color: rgba(0, 0, 0, 0.5); /* Add a slight background color for better text visibility */
    padding: 20px;
    border-radius: 10px;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.5em;
  }
  
  .login-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: 1em;
    }
  
    p {
      font-size: 1.2em;
    }
  
    .login-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 1.5em;
    }
  
    p {
      font-size: 1em;
    }
  
    .login-button {
      padding: 6px 12px;
      font-size: 0.8rem;
    }
  }
  