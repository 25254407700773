/* ProgressBar.css */

.progress-bar-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin: 20px 0;
}

.status-box {
  flex: 1 1 200px; /* Minimum width for smaller screens */
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  min-height: 200px; /* Minimum height */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.status-box p {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.response-box {
  margin-top: 10px;
}

.rectangle {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.rowli {
  background-color: #4caf50;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  min-width: 30px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nrowli {
  background-color: #d30303;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  min-width: 30px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rowli:not(.rowli):hover {
  background-color: #45a049;
}

@media screen and (max-width: 768px) {
  .status-box {
    flex: 1 1 100%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .status-box {
    padding: 10px;
    min-height: 150px; /* Adjust minimum height for very small screens */
  }

  .rowli {
    font-size: 12px; /* Smaller text on very small screens */
    padding: 3px 8px;
    min-width: 25px;
  }
}
