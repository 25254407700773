/* Navbar.css */

/* Base styles for the navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 1rem;
  z-index: 1000;

}

.navbar-brand {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
}

/* Hide links by default on mobile */
.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0 1rem;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
}

/* Hamburger menu styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .navbar-links.active {
    display: flex;
  }

  .hamburger {
    display: flex;
  }
}
