/* src/components/MailidPass.css */

.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px; /* Adds space between tabs */
}

.tab {
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #060000;
  border-radius: 5px;
  color: white;
  transition: background-color 0.3s, transform 0.3s;
  text-align: center; /* Centers text in tabs */
  min-width: 150px; /* Ensures minimum width for tabs */
}

.tab:hover {
  background-color: #333;
  transform: scale(1.05);
}

.tab.active {
  background-color: #035f17;
  color: white;
  border-color: #007bff;
}

button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 20px; /* Ensures spacing from tabs */
}

button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

button:active {
  transform: scale(1);
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  color: red;
  font-size: 14px;
  margin-top: 20px;
}

div {
  text-align: center;
  margin-top: 50px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .tab {
    padding: 8px 16px;
    min-width: 120px; /* Adjusts minimum width for smaller screens */
  }

  button {
    padding: 8px 16px;
    font-size: 14px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .tab {
    padding: 6px 12px;
    min-width: 100px; /* Further adjusts minimum width for smaller screens */
  }

  button {
    padding: 6px 12px;
    font-size: 12px;
  }

  h2 {
    font-size: 18px;
  }

  p {
    font-size: 10px;
  }

  div {
    margin-top: 30px; /* Adjusts margin for smaller screens */
  }
}
